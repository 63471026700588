import React, { useEffect, useState } from 'react';
import { render, hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { HelmetProvider } from 'react-helmet-async';
import './index.scss';
import { useClearCache } from 'react-clear-cache';
import reportWebVitals from './reportWebVitals';
import store from './redux/store';
import Routes from './routes';
import { BillsInfoProvider } from './context/BillsInfoContext/BillsInfoContext';
import { OutboundShipmentProvider } from './context/OutboundShipmentContext/OutboundShipmentContext';
import { AlphaProvider } from './context/AlphaContext/AlphaContext';
import { PersonalShopperProvider } from './context/PersonalShopperContex/PersonalShopperContex';
import AlphaShipmentProvider from './context/AlphaContext/AlphaShipmentContext';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const persistor = persistStore(store);
const rootElement = document.getElementById('root');

const BaseComponent: React.FC = () => {
  const [dataObj, setDataObj] = useState({});
  const [outboundData, setOutboundData] = useState({});
  const [alphaData, setAlphaData] = useState({});
  const [shopperData, setShopperData] = useState({});

  // const { isLatestVersion, emptyCacheStorage } = useClearCache();

  // useEffect(() => {
  //   !isLatestVersion && emptyCacheStorage();

  //   const caching = () => {
  //     const version = localStorage.getItem('version');
  //     if (version !== packageJson.version) {
  //       if ('caches' in window) {
  //         caches.keys().then((names) => {
  //           // Delete all the cache files
  //           names.forEach((name) => {
  //             caches.delete(name);
  //           });
  //         });

  //         // Makes sure the page reloads. Changes are only visible after you refresh.
  //         (window.location.reload as (cache: boolean) => void)(true);
  //       }

  //       localStorage.clear();
  //       localStorage.setItem('version', packageJson.version);
  //     }
  //   };
  //   caching();
  // }, []);

  return (
    <React.StrictMode>
      <HelmetProvider>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            {/* <ErrorBoundary> */}
            <AlphaProvider
              value={{
                alphaData,
                setAlphaData,
              }}
            >
<AlphaShipmentProvider  >



              <OutboundShipmentProvider
                value={{
                  outboundData,
                  setOutboundData,
                }}
              >
                <BillsInfoProvider
                  value={{
                    dataObj,
                    setDataObj,
                  }}
                >
                  <PersonalShopperProvider
                    value={{
                      shopperData,
                      setShopperData,
                    }}
                  >
                    <Routes />
                  </PersonalShopperProvider>
                </BillsInfoProvider>
              </OutboundShipmentProvider>
              </AlphaShipmentProvider>
            </AlphaProvider>
            {/* </ErrorBoundary> */}
          </PersistGate>
        </Provider>
      </HelmetProvider>
    </React.StrictMode>
  );
};

if (rootElement?.hasChildNodes()) {
  hydrate(<BaseComponent />, rootElement);
} else {
  render(<BaseComponent />, rootElement);
}

serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
