import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ModalAction, ModalState } from '../../interfaces';
import { initialModalState } from '../../interfaces/initialStates';

const modal = createSlice({
  name: 'modal',
  initialState: initialModalState,
  reducers: {
    showModal<OwnProps>(
      state: ModalState<OwnProps>,
      action: PayloadAction<ModalAction<OwnProps>>
    ) {
      state.modalProps = {
        ...state.modalProps,
        ...action.payload.modalProps,
        isOpen: true,
      };
      state.ownProps = action.payload.ownProps;
      state.component = action.payload.component;
    },
    hideModal(state: ModalState<any>) {
      state.modalProps = {
        isOpen: false,
      };
      state.ownProps = undefined;
      state.component = null;
    },
  },
});

export const { showModal, hideModal } = modal.actions;

export default modal.reducer;
