import React from 'react';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import ReactMarkdownWithHtml from 'react-markdown/with-html';
import { PropsFromRedux } from '.';
import { Data} from './data-content';

interface Props extends PropsFromRedux {
    faq: Data;
  }


const StateAddress: React.FC<Props> = ({ faq }) => {
    const [open, setOpen] = React.useState<boolean>(false);
    return (
        <div key={faq.id}>
          <div
            role="button"
            tabIndex={0}
            onKeyPress={() => null}
            onClick={() => setOpen(!open)}
            className="flex border-b px-2 py-4 border-gray-200"
          >
            <h4 className="font-semibold flex-grow text-lg">{faq.state}</h4>
            {open ? <IoIosArrowUp /> : <IoIosArrowDown />}
          </div>
    
          {open && (
            <div className="w-full transform transition-all flex flex-wrap  py-5 text-lg ">
              <div className="w-full px-2">
                  <ReactMarkdownWithHtml escapeHtml={false}>
                  {/* {faq.address?.map((address) => (
                    <div>
                        {address}
                      </div>
                  
                  )} */}
                     {faq.address}
                  </ReactMarkdownWithHtml>
              </div>
            </div>
          )}
        </div>
      );
  };
  
  export default StateAddress;