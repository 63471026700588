import React, { useState } from 'react';
import { MdArrowBack } from 'react-icons/md';
import { Link } from 'react-router-dom';
import SideMenu from '../../SideMenu';
import PageHeader from '../../common/PageHeader';
import koraPay from '../../../assets/images/koraIcon.png';
import sterling from '../../../assets/images/sterlingLogo.png';
import paystack from '../../../assets/images/PaystackIcon.png';
import PaymentSuccess from '../../PersonalShoppingForm/Modals/PaymentSuccess';

// eslint-disable-next-line arrow-body-style
const Payment = () => {
  const [showPaymentOptions, setShowPaymentOptions] = useState(false);
  const [showPaymentSuccessModal, setShowPaymentSuccessModal] = useState(false); // State for showing PaymentSuccess modal

  const togglePaymentOptions = () => {
    setShowPaymentOptions(true);
  };
  const handlePaymentOptionClick = () => {
    setShowPaymentSuccessModal(true);
  };
  return (
    <div className="container flex flex-wrap py-10 mx-auto lg:py-20">
      <div className="w-full">
        {!showPaymentOptions ? (
          <>
            <h4 className="mt-5 mb-4 font-bold sm:text-2xl text-md">
              <Link to="/wallet/international-shipments" type="button">
                <MdArrowBack className="mr-5 text-2xl" />
              </Link>
              Select Currency
            </h4>

            <div className="p-0 mb-10 bg-white rounded-lg sm:p-10">
              <button
                type="button"
                className="flex w-full gap-3 px-3 py-4 mb-1 transition duration-300 ease-in-out rounded opacity-100 cursor-pointer hover:opacity-50"
                style={{ backgroundColor: '#F7F9FB' }}
                onClick={togglePaymentOptions}
              >
                <div>
                  <p style={{ color: '#121212', fontWeight: 'bold' }}>NGN </p>
                </div>
              </button>
              <button
                type="button"
                className="flex w-full gap-3 px-3 py-4 mb-1 transition duration-300 ease-in-out rounded opacity-100 cursor-pointer hover:opacity-50"
                style={{ backgroundColor: '#F7F9FB' }}
                onClick={togglePaymentOptions}
              >
                <div>
                  <p style={{ color: '#121212', fontWeight: 'bold' }}>USD </p>
                </div>
              </button>
            </div>
          </>
        ) : (
          <>
            <h4 className="mt-5 mb-4 font-bold sm:text-2xl text-md">
              <Link to="/wallet/international-shipments" type="button">
                <MdArrowBack className="mr-5 text-2xl" />
              </Link>
              Payment Options
            </h4>
            <div className="p-0 mb-10 bg-white rounded-lg sm:p-10">
              <button
                type="button"
                className="flex w-full gap-3 px-3 py-4 mb-1 transition duration-300 ease-in-out rounded opacity-100 cursor-pointer hover:opacity-50"
                style={{ backgroundColor: '#F7F9FB' }}
                onClick={handlePaymentOptionClick} // Handle click to show modal
              >
                <img src={paystack} alt="GIG App" className="w-12 " />

                <div className="flex flex-col text-left">
                  <p style={{ color: '#121212', fontWeight: 'bold' }}>
                    Paystack
                  </p>
                  <small>For both local and international payments.</small>
                </div>
              </button>
              <button
                type="button"
                className="flex w-full gap-3 px-3 py-4 mb-1 transition duration-300 ease-in-out rounded opacity-100 cursor-pointer hover:opacity-50"
                style={{ backgroundColor: '#F7F9FB' }}
              >
                <img src={sterling} alt="GIG App" className="w-12 " />

                <div className="flex flex-col text-left">
                  <p style={{ color: '#121212', fontWeight: 'bold' }}>
                    Sterling
                  </p>
                  <small>For both local and international payments.</small>
                </div>
              </button>
              <button
                type="button"
                className="flex w-full gap-3 px-3 py-4 mb-1 transition duration-300 ease-in-out rounded opacity-100 cursor-pointer hover:opacity-50"
                style={{ backgroundColor: '#F7F9FB' }}
              >
                <img src={koraPay} alt="GIG App" className="w-12 " />

                <div className="flex flex-col text-left">
                  <p style={{ color: '#121212', fontWeight: 'bold' }}>
                    KoraPay
                  </p>
                  <small>For Nigerian payments only.</small>
                </div>
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Payment;
