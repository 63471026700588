import React from 'react';
import { PropsFromRedux } from '.';
import { groupBy } from '../../utils/common.utils';
import { dataContent } from './data-content';
import OfficeGroup from './OfficeGroup';

interface Props extends PropsFromRedux {}

const OfficesData:React.FC<Props> = (props) => {
    const groupedData = groupBy(dataContent, 'category');
    return (
        <div className="flex font-serif min-h-full flex-col items-stretch w-full">
          <main className="flex w-full">
            <div className="w-full my-10">
                    {Object.keys(groupedData).map((key) => (
                    <OfficeGroup key={key} data={groupedData[key]} title={key} />
                ))}
              </div>
          </main>
        </div>
  );
  };

export default OfficesData;