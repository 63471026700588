// import React, { Suspense, useCallback } from 'react';
import React, { Suspense, useEffect } from 'react';
import { Route, Switch, Router } from 'react-router-dom';
import ReactGA from 'react-ga';
// import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { PropsFromRedux } from '.';
import ScrollToTop from './ScrollToTop';
import ErrorPage from '../pages/Error/Error';
import { history } from '../redux/store';
import * as serviceWorker from '../serviceWorkerRegistration';
import MFAQs from '../pages/MFAQs';
import OurOffices from '../pages/OurOffices';
import AllCountries from '../pages/AllCountries';
import Payment from '../components/WalletTransactions/InternationalShipmentForm/Payment';
// import Promo from '../pages/Promo';

// import BillSummary from '../pages/BillSummary/BillSummary';

const Layout = React.lazy(() => import('../components/Layout'));
const Home = React.lazy(() => import('../pages/Home'));
const AboutUs = React.lazy(() => import('../pages/AboutUs'));
const ContactUs = React.lazy(() => import('../pages/ContactUs'));
const AlphaProduct = React.lazy(() => import('../pages/AlphaProduct'));
// const Location = React.lazy(() => import('../pages/Location'));
const ServicePortfolio = React.lazy(() => import('../pages/ServicePorfolio'));
const Service = React.lazy(() => import('../pages/Service'));
const SignUp = React.lazy(() => import('../pages/SignUp'));
const SignIn = React.lazy(() => import('../pages/SignIn'));
const QuickShipping = React.lazy(() => import('../pages/QuickShipping'));
const OverseasShipping = React.lazy(() => import('../pages/OverseasShipping'));
const ShipmentQuote = React.lazy(() => import('../pages/ShipmentQuote'));
const Wallet = React.lazy(() => import('../pages/Wallet'));
const FAQs = React.lazy(() => import('../pages/FAQs'));
// const MFAQs = React.lazy(()=> import ('../pages/MFAQs'))
const OverseasShippingForm = React.lazy(
  () => import('../components/OverseasShippingForm')
);

const PersonalShoppingForm = React.lazy(
  () => import('../components/PersonalShoppingForm')
);

const MyProfile = React.lazy(() => import('../pages/MyProfile'));
const Termsandconditions = React.lazy(
  () => import('../pages/Termsandconditions')
);
const PrivacyPolicy = React.lazy(() => import('../pages/PrivacyPolicy'));

const ReportIssues = React.lazy(() => import('../pages/ReportIssues'));
const XpressDropoff = React.lazy(() => import('../pages/XpressDropoff'));
const HomeDeliveryLocations = React.lazy(
  () => import('../pages/HomeDeliveryLocations')
);
const ForgotPassword = React.lazy(() => import('../pages/ForgotPassword'));
const VerifyOtp = React.lazy(() => import('../pages/VerifyOtp'));
const Countries = React.lazy(() => import('../pages/Countries'));
const ClassEcommerce = React.lazy(() => import('../pages/ClassEcommerce'));
const ChinaOversea = React.lazy(() => import('../pages/ChinaOversea'));
const BillPayment = React.lazy(() => import('../pages/BillPayment'));
const BillSummary = React.lazy(() => import('../pages/BillSummary'));
const Redirect = React.lazy(() => import('../pages/Redirect'));
const InternationalShipmentOption = React.lazy(
  () => import('../pages/InternationalShipmentOption')
);
const OutboundShipment = React.lazy(() => import('../pages/OutboundShipment'));
const OutboundShipmentDetails = React.lazy(
  () => import('../pages/OutboundShipmentDetails')
);
const CodAccount = React.lazy(() => import('../pages/CodAccount'));
const ShipmentTracking = React.lazy(() => import('../pages/ShipmentTracking'));

const PersonalShopping = React.lazy(() => import('../pages/PersonalShopping'));
const PersonalShoppingDetails = React.lazy(
  () => import('../pages/PersonalShoppingDetails')
);
const PersonalShoppingForms = React.lazy(
  () => import('../pages/PersonalShoppingForms')
);
const PersonalShoppingHistory = React.lazy(
  () => import('../pages/PersonalShoppingHistory')
);
const PersonalShoppingInvoice = React.lazy(
  () => import('../pages/PersonalShoppingInvoice')
);
const PersonalShopperSummary = React.lazy(
  () => import('../pages/PersonalShopperSummary')
);

const Promo = React.lazy(() => import('../pages/Promo'));
const RedirectPayment = React.lazy(
  () => import('../pages/RedirectPayment/RedirectPayment')
);

const TrackDeliveryManifest = React.lazy(
  () => import('../pages/TrackDeliveryManifest')
);

interface RouterProps extends PropsFromRedux {}

const Routes: React.FC<RouterProps> = () => {
  const TRACKING_ID = 'G-RD02LKDQY8'; // OUR_TRACKING_ID
  ReactGA.initialize(TRACKING_ID);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  const [newVersionAvailable, setNewVersionAvailable] =
    React.useState<boolean>(false);
  const [waitingWorker, setWaitingWorker] = React.useState<any>({
    postMessage: (message: any) => message,
  });

  const onServiceWorkerUpdate = (registration: ServiceWorkerRegistration) => {
    setWaitingWorker(registration?.waiting);
    if (registration) setNewVersionAvailable(true);
  };

  React.useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      serviceWorker.register({ onUpdate: onServiceWorkerUpdate });
    }
  }, []);

  React.useEffect(() => {
    const updateServiceWorker = () => {
      waitingWorker && waitingWorker.postMessage({ type: 'SKIP_WAITING' });
      setNewVersionAvailable(false);
      window.location.reload();
    };

    if (newVersionAvailable) {
      // toast(
      //   ({ closeToast }) => (
      //     <div>
      //       <p>A new version of GIGL is available</p>
      //       <p>
      //         Click{' '}
      //         <button
      //           onClick={updateServiceWorker}
      //           type="button"
      //           className="underline"
      //           aria-label="reload page"
      //         >
      //           Here
      //         </button>{' '}
      //         to reload
      //       </p>
      //     </div>
      //   ),
      //   {
      //     autoClose: false,
      //     type: 'dark',
      //     position: 'bottom-right',
      //   }
      // );
      updateServiceWorker();
    }
    // setNewVersionAvailable(false)
  }, [newVersionAvailable, waitingWorker]);

  const Loader: React.FC = ({ children }) => (
    <Suspense
      fallback={
        <div className="w-full h-screen bg-gray-100">
          <div className="w-full bg-white h-14 sm:h-28" />
          <div className="container flex flex-wrap mx-auto my-8 sm:flex-nowrap">
            <div className="w-full m-2 bg-gray-200 rounded-lg sm:w-1/4 h-80 sm:m-5" />
            <div className="w-full m-2 bg-gray-200 rounded-lg sm:w-3/4 h-96 sm:m-5" />
          </div>
        </div>
      }
    >
      {children}
    </Suspense>
  );

  return (
    <Router history={history}>
      <ScrollToTop />
      <Loader>
        <Switch>
          <Route exact path="/">
            <Layout component={<Home />} />
          </Route>
          <Route exact path="/faqs">
            <Layout component={<FAQs />} />
          </Route>
          <Route exact path="/faq">
            <Layout component={<FAQs />} />
          </Route>
          <Route exact path="/mfaq">
            <MFAQs />
          </Route>
          <Route exact path="/terms">
            <Layout component={<Termsandconditions />} />
          </Route>
          <Route exact path="/privacy-policy">
            <Layout component={<PrivacyPolicy />} />
          </Route>
          <Route path="/promo">
            <Promo /> RedirectPayment
          </Route>
          <Route path="/redirect-payment">
            <RedirectPayment />
          </Route>
          <Route exact path="/report-issues">
            <Layout component={<ReportIssues />} />
          </Route>
          <Route exact path="/about-us">
            <Layout component={<AboutUs />} />
          </Route>
          <Route exact path="/about-gigl">
            <Layout component={<AboutUs />} />
          </Route>
          <Route exact path="/contact-us">
            <Layout component={<ContactUs />} />
          </Route>
          {/* <Route path="/locations">
            <Layout component={<Location />} />
          </Route> */}
          <Route path="/home-delivery">
            <Layout component={<HomeDeliveryLocations />} />
          </Route>
          <Route exact path="/services-portfolio">
            <Layout component={<ServicePortfolio />} />
          </Route>
          <Route exact path="/track/:trackingCode">
            <Layout component={<Home />} />
          </Route>
          <Route exact path="/shipment/:trackingCode">
            <Layout component={<ShipmentTracking />} />
          </Route>
          <Route exact path="/order/:trackingAlpha">
            <Layout component={<Home />} />
          </Route>
          <Route exact path="/sign-up">
            <Layout requiresNoAuth component={<SignUp />} />
          </Route>
          <Route exact path="/sign-in">
            <Layout requiresNoAuth component={<SignIn />} />
          </Route>
          <Route exact path="/forgot-password">
            <Layout requiresNoAuth component={<ForgotPassword />} />
          </Route>
          <Route exact path="/verify-otp">
            <Layout requiresAuth component={<VerifyOtp />} />
          </Route>
          <Route path="/giggo-delivery-app">
            <Layout requiresAuth component={<QuickShipping />} />
          </Route>
          <Route path="/overseas-shipping/ship">
            <Layout requiresAuth component={<OverseasShippingForm />} />
          </Route>

          <Route path="/outbound-shipment">
            <Layout requiresAuth component={<OutboundShipment />} />
          </Route>

          <Route exact path="/overseas-shipping">
            <Layout component={<OverseasShipping />} />
          </Route>
          <Route path="/get-a-quote">
            <Layout component={<ShipmentQuote />} />
          </Route>
          <Route path="/dropoff">
            <Layout requiresAuth component={<XpressDropoff />} />
          </Route>
          <Route exact path="/my-profile">
            <Layout requiresAuth component={<MyProfile />} />
          </Route>
          <Route path="/wallet">
            <Layout requiresAuth component={<Wallet />} />
          </Route>
          <Route exact path="/wallet/fund">
            <Layout requiresAuth component={<Wallet />} />
          </Route>
          <Route exact path="/class-ecommerce">
            <Layout component={<ClassEcommerce />} />
          </Route>
          <Route exact path="/china-oversea">
            <Layout component={<ChinaOversea />} />
          </Route>
          <Route path="/bill-payment">
            <Layout requiresAuth component={<BillPayment />} />
          </Route>

          <Route exact path="/bill-summary">
            <Layout requiresAuth component={<BillSummary />} />
          </Route>
          <Route exact path="/outbound-details">
            <Layout requiresAuth component={<OutboundShipmentDetails />} />
          </Route>
          <Route path="/redirect">
            <Layout component={<Redirect />} />
          </Route>

          <Route path="/shipment-option">
            <Layout component={<InternationalShipmentOption />} />
          </Route>

          <Route path="/alpha-product/:alphaCode">
            <Layout component={<AlphaProduct />} />
          </Route>
          <Route path="/cod-account">
            <Layout requiresAuth component={<CodAccount />} />
          </Route>
          <Route path="/personal-shopping">
            <Layout requiresAuth component={<PersonalShopping />} />
          </Route>
          <Route path="/personal-shopping-details">
            <Layout requiresAuth component={<PersonalShoppingDetails />} />
          </Route>

          <Route path="/personal-shoppingforms">
            <Layout requiresAuth component={<PersonalShoppingForms />} />
          </Route>
          <Route path="/personal-shoppingforms/itemdetails">
            <Layout requiresAuth component={<PersonalShoppingForm />} />
          </Route>
          <Route path="/shopping-history">
            <Layout requiresAuth component={<PersonalShoppingHistory />} />
          </Route>
          <Route path="/shopping-summary">
            <Layout requiresAuth component={<PersonalShopperSummary />} />
          </Route>
          <Route path="/shopping-invoice">
            <Layout requiresAuth component={<PersonalShoppingInvoice />} />
          </Route>
          <Route path="/our-offices">
            <Layout component={<OurOffices />} />
          </Route>
          <Route path="/deliverymanifest/:manifestcode/:waybillnumber">
            <Layout component={<TrackDeliveryManifest />} />
          </Route>
          <Route path="/international-shipments/:requestcode/payment">
            <Layout requiresAuth component={<Payment />} />
          </Route>
          {/* <Route exact path="/our-offices">
            <OurOffices />
            </Route> */}

          <Route path="/all-countries">
            <AllCountries />
          </Route>

          <Route exact path="/gig-alpha">
            <Layout
              component={
                <ErrorPage description="" error="Anticipate the phenomenal" />
              }
            />
          </Route>
          <Route exact path="/:service">
            <Layout component={<Service />} />
          </Route>
          <Route exact path="/location/:country">
            <Layout component={<Countries />} />
          </Route>

          <Route>
            <Layout
              component={
                <ErrorPage
                  error="404"
                  description="The page you are looking for does not exist"
                />
              }
            />
          </Route>
        </Switch>
      </Loader>
    </Router>
  );
};

export default Routes;
