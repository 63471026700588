import React from 'react';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { useParams } from 'react-router';
import Seo from '../../components/Seo/Seo';
import ErrorPage from '../Error/Error';
import { locations, SERVICE_COUNTRIES } from '../Location/locations';

interface Props {
  pageDataProps?: SERVICE_COUNTRIES;
}

const AllCountries: React.FC<Props> = ({ pageDataProps }) => {
  const [pageData, setPageData] = React.useState<SERVICE_COUNTRIES | null>();
  const { country } = useParams<{ country: string }>();
  const [open, setOpen] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (pageDataProps) {
      setPageData(pageDataProps);
    } else {
      const data = locations.find((page) =>
        page.aliases.includes(country?.toLowerCase())
      );
      setPageData(data || null);
    }
  }, [country, pageDataProps]);

  if (!pageData)
    return (
      <ErrorPage
        error={pageData === null ? '404' : 'Loading...'}
        description={
          pageData === null
            ? 'GIG currently does not operate in the selected location'
            : ''
        }
      />
    );

  return (
    <div className="w-full">
      <Seo title={pageData.country} description="" />
      <div className="page-header-bg">
        <div className="container mx-auto flex flex-col justify-center items-center py-20">
          <h1 className="font-bold mb-5 text-4xl">{pageData.country}</h1>
        </div>
      </div>
   
      {pageData?.states?.map((state) => (
        <div
          key={state.name}
          className="container p-5 flex flex-wrap rounded md:p-10 bg-white mb-10 mx-auto"
        >
          <div className="w-full md:w-1/4">
            <h3 className="font-semibold text-2xl mb-10">{state.name}</h3>
          </div>
          <div className="w-full flex flex-wrap md:w-3/4">
            {state.serviceCenters?.map((servicecenter) => (
              <div className="w-full md:w-1/2 px-2" key={servicecenter.name}>
                <div
                            role="button"
                            tabIndex={0}
                            onKeyPress={() => null}
                            onClick={() => setOpen(!open)}
                            className="flex border-b px-2 py-4 border-gray-200"
                        >
                    <h4 className="font-semibold flex-grow text-lg">{servicecenter.name}</h4>
                     {open ? <IoIosArrowUp /> : <IoIosArrowDown />}
                    {/* <p className="font-medium text-primary text-lg mb25">
                        {servicecenter.name}
                    </p> */}
                </div>
                {open && (
                    <p className="text-sm mb-10">{servicecenter.address}</p>
                )}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default AllCountries;
