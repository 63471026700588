import React, { createContext, useState } from 'react';

export const ShipmentContext = createContext();

const AlphaShipmentProvider = ({ children }) => {
  const [shipmentData, setShipmentData] = useState({});
  const { Provider } = ShipmentContext;
  return (
    // eslint-disable-next-line react/jsx-filename-extension
    <Provider value={{ shipmentData, setShipmentData }}>{children}</Provider>
  );
};

export default AlphaShipmentProvider;
