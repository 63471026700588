import React from 'react';
import { PropsFromRedux } from '.';
import { groupBy } from '../../utils/common.utils';
import { faqData } from './faq-data';
import FAQGroup from './FAQGroup';

interface Props extends PropsFromRedux {}

const MFAQs: React.FC<Props> = (props) => {
  const groupedData = groupBy(faqData, 'category');
  return (
        <div className="flex font-serif min-h-full flex-col items-stretch w-full">
          <main className="flex w-full">
         
                 <div className="w-full my-10">
                    {Object.keys(groupedData).map((key) => (
                    <FAQGroup key={key} data={groupedData[key]} title={key} />
                ))}
              </div>
          </main>
        </div>
  );
};

export default MFAQs;
