import { uid } from 'uid';

export interface FAQData {
  id: string;
  category: string;
  question: string;
  answer: string;
}

export const faqData: FAQData[] = [
  {
    id: uid(8),
    category: 'GIG Logistics (GIGL) - Overseas Shipping',
    question: 'What is Overseas Shipping?',
    answer:
      'Overseas shipping is a GIGL solution that allows you ship in and export items from any of your favorite stores abroad, anywhere in the world and ship to your doorstep in Nigeria.',
  },
  {
    id: uid(8),
    category: 'GIG Logistics (GIGL) - Overseas Shipping',
    question: 'How do I ship from the USA/UK into Nigeria?',
    answer:
      'Easy! Simply download the <a href="https://play.google.com/store/apps/details?id=ng.giglogistics.giglgo" class="underline">GIGGo App</a> here to get started. Then click “Overseas Shipping” on the GIGGo App, select country and follow the prompts. The GIGL overseas shipment process is seamless and efficient.',
  },
  {
    id: uid(8),
    category: 'GIG Logistics (GIGL) - Overseas Shipping',
    question: 'Who can ship Overseas?',
    answer:
      'Anyone with a fully updated user profile on the GIGGo App can ship Overseas. For E-commerce merchants, you will need to use your regular account to ship.',
  },
  {
    id: uid(8),
    category: 'GIG Logistics (GIGL) - Overseas Shipping',
    question: 'How do I make payment?',
    answer:
      'After we receive your item physically at either our UK or USA office, you will get a Payment link through which you can make payments. The link offers you the option of making secured payments through your funded wallet on the GIGGo App, a third-party payment platform (Paystack/Flutterwave), or your Bank Card.',
  },
  {
    id: uid(8),
    category: 'GIG Logistics (GIGL) - Overseas Shipping',
    question:
      'Will my parcel be delivered to my final destination anywhere in Nigeria?',
    answer:
      '<p class="mb-2">Yes, we will deliver anywhere in Nigeria so long as your preferred address is listed under the GIGL home delivery locations.</p> <p>We are constantly striving to serve you better and are rapidly expanding to cover all locations within Nigeria.</p>',
  },
  {
    id: uid(8),
    category: 'GIG Logistics (GIGL) - Overseas Shipping',
    question: 'Will GIGL require you to pay into any account?',
    answer:
      '<p>No, GIGL will never ask you to pay into any numbered account. All payments are either deducted straight from your GIGGo wallet or through an authentic and secure payment link that offers you 3 payment options –</p> <ul class="list-disc pl-8"><li>GIGGo Wallet</li><li>Third party payment platform (Paystack or Flutterwave)</li><li>Bank Debit or Credit  Card</li></ul>',
  },
  {
    id: uid(8),
    category: 'GIG Logistics (GIGL) - Overseas Shipping',
    question: 'How much is the shipping fee?',
    answer:
      '<a href="/overseas-shipping" class="underline">Click here to view prices</a>',
  },
  {
    id: uid(8),
    category: 'GIG Logistics (GIGL) - Overseas Shipping',
    question:
      'What is the expected time of arrival (ETA) for international shipments?',
    answer:
      '<p>US to Nigeria: 7-10 days</p><p>UK to Nigeria: 3-5 days</p>',
  },
  {
    id: uid(8),
    category: 'GIG Logistics (GIGL) - Overseas Shipping',
    question: 'Do you ship all kind of items?',
    answer:
      'Yes, excluding items prohibited by law enforcement agencies. The size of your item is no barrier as special arrangements are available for supersized items',
  },
  {
    id: uid(8),
    category: 'GIG Logistics (GIGL) - Overseas Shipping',
    question: 'Which countries do you ship to & from Nigeria?',
    answer: 'All countries in the world.',
  },
  {
    id: uid(8),
    category: 'GIG Logistics (GIGL) - Overseas Shipping',
    question: 'What is your export rate?',
    answer: 'Visit our website for more on export rate per',
  },

  {
    id: uid(8),
    category: 'GIG Logistics - Ecommerce Merchants',
    question: 'Who is an Ecommerce Merchant in GIGL?',
    answer:
      '<p class="mb-2">A GIGL Ecommerce Merchant is anyone signed up on the GIGL’s Ecommerce Merchant platform, either on the GIGGo App or through the Ecommerce merchant portal on the GIGL website. This platform is designed to give massively discounted rates and plenty other benefits to customers who deliver products frequently and in large quantity.</p> <p>Its flexibility and additional offerings make it an ideal choice for SMEs or Social/Ecommerce businesses. If you sell online or on Instagram, Twitter or Facebook, this is great for you!</p>',
  },
  {
    id: uid(8),
    category: 'GIG Logistics - Ecommerce Merchants',
    question: 'How do I become an Ecommerce Merchant?',
    answer:
      'By signing up on the GIGGo App as an Ecommerce merchant or <a href="https://www.gigl-go.com/merchantSignUp" target="_blank" class="underline">through the website</a>',
  },
  {
    id: uid(8),
    category: 'GIG Logistics - Ecommerce Merchants',
    question: 'What do I stand to benefit as an Ecommerce Merchant?',
    answer:
      '<p class="mb-2">The GIGL Ecommerce Merchant platform features 2 plans called GIGL Basic and GIGL Class.</p><p> The GIGL Basic plan offers you:</p><ul class="mb-4 list-disc pl-8"><li>Shipping discount worth (2.5%) two percent</li><li>Ecommerce business growth kit</li></ul><p>On the GIGL Class plan, you get</p><ul class="list-disc pl-8"><li>Fifteen percent (15%) discount on local shipping fees <li>Ten percent (10%) discount on international shipping fees</li><li>Free insurance cover on shipments capped at 100k</li><li>Priority Shipping</li><li>No handling charges on Cash on Delivery (COD) & Transfer on Delivery (TOD)</li><li>Free bulk pickup for interstate deliveries</li><li>Ecommerce growth kit</li><li>A dedicated Account Officer</li><li>Media visibility on Digital Millionaire for business growth</li></ul>',
  },
  {
    id: uid(8),
    category: 'GIG Logistics - Ecommerce Merchants',
    question:
      'How much do I need to sign up on the Ecommerce Merchant Platform?',
    answer:
      'The Basic Plan is free. On the Class Plan, you will need to subscribe monthly at ₦3,999',
  },
  {
    id: uid(8),
    category: 'GIG Logistics - Ecommerce Merchants',
    question: 'Do I need to have a registered company/business?',
    answer: 'No',
  },
  {
    id: uid(8),
    category: 'GIG Logistics - Ecommerce Merchants',
    question:
      'Can I shop and ship from international sites (e.g. Amazon) into Nigeria?',
    answer:
      'Yes please. You can shop and ship directly through the stores listed on the GIGGo App or Shop from your favorite international site and ship through the <a href="https://play.google.com/store/apps/details?id=ng.giglogistics.giglgo" class="underline" target="_blank">GIGGo App.</a>',
  },
  {
    id: uid(8),
    category: 'GIG Logistics - Ecommerce Merchants',
    question: 'How do I pay for my deliveries?',
    answer:
      'By simply funding your wallet via the GIGGo App or the Ecommerce merchant portal on the website. Over the counter payments are not allowed.',
  },
  {
    id: uid(8),
    category: 'GIG Logistics - Ecommerce Merchants',
    question: 'How do I track my shipments?',
    answer:
      'You can track it by inserting your waybill number into the “track tab” on your GIGGo App, Ecommerce merchant portal or website homepage.',
  },
  {
    id: uid(8),
    category: 'GIG Logistics - Ecommerce Merchants',
    question: 'Can I process C.O.D transactions on the GIGGo App?',
    answer:
      'This function is not yet available at the moment. However, you can physically process this at any of our experience centers in the interim.',
  },
  {
    id: uid(8),
    category: 'GIG Logistics - Ecommerce Merchants',
    question: 'When do I get paid for my C.O.D transactions?',
    answer:
      'You get paid in 7 working days after successful delivery has been confirmed',
  },
  {
    id: uid(8),
    category: 'GIG Logistics - Ecommerce Merchants',
    question: 'What Is the delivery timeline?',
    answer:
      '2-3 working days (under the right conditions, depending on the zone and region in the country). Same day deliveries are available in select cities- Lagos, Port- Harcourt and Abuja.',
  },
  {
    id: uid(8),
    category: 'GIG Logistics – GIGGo App',
    question: 'What is the GIGGo App?',
    answer:
      'The GIGGo App by GIG Logistics is an efficient on-demand app built to solve your logistics needs and be your delivery companion. It handles all your requests ranging from automated deliveries to pick-up requests, overseas shipping, payment for transactions and lots more. Use it on the go, at your convenience, from anywhere in the world on your own terms.',
  },
  {
    id: uid(8),
    category: 'GIG Logistics – GIGGo App',
    question: 'What is the timeline for pick up?',
    answer: 'Pick up is real time but also based on availability of a rider.',
  },
  {
    id: uid(8),
    category: 'GIG Logistics – GIGGo App',
    question: 'What happens if the rider cancels my request?',
    answer:
      'Pick up will be assigned to another available rider. Shipping fee will also be refunded if the customer cancels the request.',
  },
  {
    id: uid(8),
    category: 'GIG Logistics – GIGGo App',
    question: 'Will a customer be charged after the 5 minutes wait time?',
    answer: 'Yes',
  },
  {
    id: uid(8),
    category: 'GIG Logistics – GIGGo App',
    question: 'What type of vehicles are available for delivery?',
    answer:
      '<p>Depending on the size of the shipment, all of the following are available to be used for delivery.</p> <ul class="py-4 list-disc pl-8"><li>Motor Bike</li><li>Car</li><li>Mini Van</li><li>Truck</li></ul>',
  },
  {
    id: uid(8),
    category: 'GIG Logistics – GIGGo App',
    question: 'What happens when a shipment gets damaged or missing?',
    answer:
      'This rarely happens but in the event that it does, GIG Logistics will make full refund to the customer based on the value declared of item(s) missing or damaged.',
  },
  {
    id: uid(8),
    category: 'GIG Logistics – GIGGo App',
    question: 'From what locations can I order a pick-up service?',
    answer:
      'You can use the GIGGo App from any city in the world to order pick up from Lagos, Abuja, Benin, Port Harcourt, Asaba, Awka.',
  },
  {
    id: uid(8),
    category: 'GIG Logistics – GIGGo App',
    question: 'Is the GIGGo App available 24/7?',
    answer:
      'Yes, it is. However, pick-up requests will only be assigned to a rider between 6am-6pm. Any request after 6pm will be put on the priority queue and assigned the next day.',
  },
  {
    id: uid(8),
    category: 'GIG Logistics – GIGGo App',
    question: 'What is the mode of payment on the GIGGo App?',
    answer:
      'GIGGo is a strictly wallet-based system. No cash is used when transacting through the App',
  },
  {
    id: uid(8),
    category: 'GIG Logistics – GIGGo App',
    question: 'What is Xpress Drop-Off?',
    answer:
      'It is a feature on the GIGGo App that helps you fast track shipment processing. Simply create shipment on the GIGGo App, then present the unique code generated by the App to any of our agents at your preferred GIGL experience center for prompt service and Drop-Off your shipment. Use this feature to save time and skip queues at our experience centers!',
  },
  {
    id: uid(8),
    category: 'GIG Logistics – GIGGo App',
    question: 'How do I process payment for Xpress Drop-Off?',
    answer:
      'You can pay from your GIGGo wallet, use cash or POS at the experience center.',
  },
  {
    id: uid(8),
    category: 'GIG Logistics – GIGGo App',
    question: 'How do I fund my wallet?',
    answer:
      'Please go to ‘transaction” on the GIGGo App and click on fund wallet for instant value.',
  },
  {
    id: uid(8),
    category: 'GIG Logistics – GIGGo App',
    question:
      'How do I make payment with the GIGGo wallet for Xpress Drop-Off?',
    answer:
      'Please go to Menu, click on process service center pick-up and process payment.',
  },
  {
    id: uid(8),
    category: 'GIG Logistics – GIGGo App',
    question: 'When am I to select car for pickup?',
    answer:
      'When the shipment is either too big for a bike delivery box or too small for a van.',
  },
  {
    id: uid(8),
    category: 'GIG Logistics – GIGGo App',
    question:
      'For small shipments going outside Lagos, what vehicle type should be selected?',
    answer:
      'The vehicle type will depend on the size of the item. Please select “Bike” for items that can fit the delivery box and a car for items too small for a Van but too large for a Bike. Trucks are available for larger items like home furniture or machinery, etc. For further guidance, please contact customer service on <strong>0813 985 1120</strong>.',
  },
  {
    id: uid(8),
    category: 'GIG Logistics – GIGGo App',
    question: 'Can I create multiple shipments with 1 waybill number?',
    answer:
      'Yes, you can. Provided that all items are going to one receiver in a single location.',
  },
  {
    id: uid(8),
    category: 'GIG Logistics – GIGGo App',
    question: 'Am I allowed to tip the rider?',
    answer:
      'No please. Our riders earn sufficiently to be able to serve you daily.',
  },
  {
    id: uid(8),
    category: 'GIG Logistics – GIGGo App',
    question: 'How do I report a complaint?',
    answer:
      'Please call our Contact Center on 0813 985 1120 or send an email to info@giglogistics.com. Our care line is available from 7 am- 9pm daily.',
  },
  {
    id: uid(8),
    category: 'GIG Logistics UK Terms and Condition',
    question: 'Overseas Shipping ( from UK to Nigeria) - what you need to know',
    answer: `<p class="font-medium">By using the overseas shipping feature on the GIGGo App, you can now shop from your favourite stores in the UK and have your item(s) delivered to your doorstep in Nigeria without restrictions. The estimated time for delivery is usually between three to four (3 – 4) business days from date of departure from our delivery hub. Our rates from the UK to all cities in Nigeria are:</p>
      <ul class="pl-5 my-5">
      <li>4.5 GBP per kg for regular Items</li>
      <li>27GBP A flat rate for same items if less than 6kg.</li>
      <li>50 GBP Laptops, Pamtops, Tablets, Ipads 7-18 inches</li>
      <li>30GBP each Children Tablets and Smartphones</li>
      <li>25GBP flat rate for used Gadgets such as the above less than the minimum of 6Kg.</li>
      <li>5GBP per Kg Used gadgets 6Kg and above</li>
      <li>30GBP for Perfumes less than 6KG</li>
      <li> While 6Kg and above will be charged at 6GBP per Kg</li>
      </ul>
      <p class="font-medium mb-5">Volume weight applies to charges in a case where it is the highest: a typical example is a Television and huge baby items. International Volumetric Weights are calculated using the formula below:</p>
      <p class="font-medium mb-5 pl-5 italic">Length x Width x Height in centimeters) divided by 6000 = Volumetric Weight in kilograms</p><p class="font-medium mb-5">Please note that the actual shipping price is subject to the volume weight of the item(s) as well as an optional insurance fee of 5% on the total value of the item(s). In case you want to ship multiple items, you can also take advantage of our consolidated shipping service while saving time and money. If you have further enquiries, we are happy to help. . Please contact us on gigluk@giglogistics.com It takes less than 5 minutes to complete a shipping request on the GIGGo App.You should try it! Handling fee applies in a case where we have to do special packing.A typical example is liquid and fragile items.</p><p lass="font-medium mb-5">Please fill the required information and provide a Government Recognized Photo Identification.(International passport or Drivers License) Follow the format below when you purchase an item online:</p><ul class="pl-5 my-5">
      <li> *YOUR NAME*</li>
      <li>GIG Logistics UK,<br />C/O Owl Global Limited,<br />149 St Paul's Avenue, Slough, Berkshire, SL2 5EN</li>
      </ul>
      <p>For further enquiry contact us via email:</p>
      <p>gigluk@giglogistics.com</p>
      <p>Thank you.</p>
    `,
  },
];
