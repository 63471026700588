import React from 'react';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import ReactMarkdownWithHtml from 'react-markdown/with-html';
import { PropsFromRedux } from '.';
import { Data } from './data-content';
import StateAddress from './StateAddress';


interface Props extends PropsFromRedux {
  title: string;
  data: Data[];
}

  const OfficeGroup: React.FC<Props> = ({ title, data }) => (
    
    <div className="w-full text-gray-900 flex flex-wrap justify-between mb-10">

        <div className="w-full md:w-1/6">
          <span className="font-semibold text-2xl">{title}</span>
            
        </div>
        <div className="w-full md:w-3/4 ">
        {/* <div className="w-full md:w-4/6"> */}
          {data.map((faq) => (
            <StateAddress key={faq.id} faq={faq} />
          ))}
        </div>
      </div>
  );
  
  export default OfficeGroup;